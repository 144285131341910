<template>
  <div class="c-app" style="background-color: #ffffff">
    <div class="c-wrapper">
      <div class="c-body">
        <div class="lds-ripple">
          <div></div>
          <div></div>
        </div>
        <p style="z-index: 1090;position: fixed; top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);margin-top: 80px;" class="font-weight-bold text-color-theme text-center">{{
    $t('sendingOrder')
          }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import poscrm from '@/services/poscrm'
import orderutil from '@/util/order'
import '@/util/loader.css'
import qs from 'qs'
import pos from '@/services/pos'

export default {
  data() {
    return {
      cartLists: [],
      table: {},
      receiptItems: [],
      receiptId: '',
      receiptObjectId: '',
    }
  },
  computed: {
    ...mapGetters(['shopLineOA']),
    uid() {
      return `${localStorage.getItem('uid')}`
    },
    shopObjectId() {
      return `${localStorage.getItem('shopObjectId')}`
    },
    shop() {
      return this.shopLineOA.find(
        (detail) => detail.objectId === this.shopObjectId
      )
    },
    tableObjectId() {
      return `${localStorage.getItem('tableObjectId')}`
    },
  },

  created() {
    this.getOrderShopSetting()
  },
  methods: {
    trackButtonClick(buttonLabel) {
      if (typeof window.gtag === 'function') {
        window.gtag('event', 'click', {
          event_label: buttonLabel,
        });
      } else {
        console.error('gtag is not defined.');
      }
    },
    async createOrder() {
      this.trackButtonClick('Pending/CreateOrder')
      const timestamp = localStorage.getItem('timestamp')
      const pax = localStorage.getItem('pax')

      const data = orderutil.orderDineIn(
        this.uid,
        this.shop,
        this.cartLists,
        this.table,
        timestamp,
        pax
      )

      try {
        const res = await poscrm({
          url: '/api/v1.1/' + this.uid + '/Receipt/createorder',
          data: data,
          method: 'post',
        });

        if (res.data.error.code === 0) {
          const receiptId = res.data.data.id
          const receiptObjectId = res.data.data.objectId

          localStorage.setItem('receiptId', receiptId)
          localStorage.setItem('receiptObjectId', receiptObjectId)
          this.updateTableStatus(receiptId, receiptObjectId)
        } else {
          sessionStorage.clear()
          localStorage.clear()
          this.$router.push('/texp?errorCode=-4001')
        }
      } catch (error) {
        console.error('Error creating order:', error)
        this.$toast.error('Failed to create order. Please try again.')
      }
    },
    async updateOrder() {
      this.receiptItems.push(...this.cartLists)

      let receiptObjectId = localStorage.getItem('receiptObjectId')
      let timestamp = localStorage.getItem('timestamp')

      let data = {
        objectId: receiptObjectId,
        uid: this.uid,
        shopObjectId: this.shopObjectId,
        receipt: {
          source: 'SELF_ORDER',
          QRTimestamp: timestamp,
          receiptStatus: 'O',
          table: {
            id: this.table.id,
            objectId: this.table.objectId,
            name: this.table.name,
          },
          receiptItems: this.receiptItems,
          objectId: this.receiptObjectId,
          id: this.receiptId,
          isVATIncluded: true,
        },
      }

      try {
        const res = await poscrm({
          url: '/api/v1.1/' + this.uid + '/Receipt/updateorder',
          data: data,
          method: 'post',
        });

        if (res.data?.error?.code === 0) {
          let receiptId = res.data?.data?.id
          let receiptObjectId = res.data?.data?.objectId

          localStorage.setItem('receiptId', receiptId)
          localStorage.setItem('receiptObjectId', receiptObjectId)

          this.updateTableStatus(receiptId, receiptObjectId)
        } else {
          sessionStorage.clear()
          localStorage.clear()
          this.$router.push('/texp?errorCode=-4002')
        }
      } catch (error) {
        console.error('Error updating order:', error)
        this.$toast.error('Failed to update order. Please try again.')
      }
    },
    async updateTableStatus(receiptId, receiptObjectId) {
      const uid = this.uid
      let shopObjectId = this.shopObjectId

      let data = {
        shopObjectId: shopObjectId,
        tableObjectId: this.table.objectId,
        status: 0,
        receiptId: receiptId,
        receiptObjectId: receiptObjectId,
      }

      try {
        await poscrm({
          url: '/api/v1.1/' + uid + '/Shop/updatetablestatus',
          data: qs.stringify(data),
          method: 'post',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        })
        sessionStorage.clear()
        this.$router.push('/dine-in/mycart?redirect=pending')
      } catch (error) {
        console.error('Error updating table status:', error)
        this.$toast.error('Failed to update table status. Please contact support.')
      }
    },
    async getReceiptTransaction() {
      try {
        const res = await poscrm.get(`/api/v1.1/${this.uid}/Receipt/data/${this.receiptObjectId}`)
        const document = res.data.data.document
        const receiptItems = document.receiptItems || []
        const table = document.table || []
        const tableStatus = table.tableStatus || ''

        if (receiptItems.length === 0) {
          this.createOrder()
        } else if (tableStatus === 'P') {
          this.$router.push('/dine-in/pending/payment')
        } else {
          this.updateOrder()
        }
      } catch (error) {
        console.error('Error fetching receipt transaction:', error)
        this.$toast.error('Failed to fetch receipt transaction. Please try again.')
      }
    },
    async getTableStatusFromFB() {
      let params = {
        shopObjectId: this.shopObjectId,
        objectId: this.tableObjectId,
      }

      try {
        const res = await poscrm.get('/api/v1.1/' + this.uid + '/Shop/gettablebyobjectid/fbdata', {
          params: params,
        });

        if (res.data.data.currentReceipt !== undefined) {
          let currentReceipt = res.data.data.currentReceipt
          this.receiptId = currentReceipt.id
          this.receiptObjectId = currentReceipt.objectId
          localStorage.setItem('receiptId', this.receiptId)
          localStorage.setItem('receiptObjectId', this.receiptObjectId)
          this.getReceiptTransaction()
        } else {
          this.createOrder()
        }
      } catch (error) {
        console.error('Error fetching table status from FB:', error)
        this.$toast.error('Failed to fetch table status. Please try again.')
      }
    },
    async getOrderShopSetting() {
      const uid = `${localStorage.getItem('uid')}`
      const shopObjectId = `${localStorage.getItem('shopObjectId')}`

      let params = {
        shopObjectId: shopObjectId,
      }

      try {
        const res = await poscrm({
          method: 'get',
          url: '/api/v2.1/' + uid + '/OrderShopSetting',
          params: params,
        });

        let orderShopSetting = res?.data?.data[0] || {}
        let qrType = orderShopSetting?.qrType

        if (qrType === 'STATIC') {
          this.verifyOrderAble()
        } else {
          this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
          this.table = JSON.parse(localStorage.getItem('table')) || []
          this.receiptId = localStorage.getItem('receiptId') || ''
          this.receiptObjectId = localStorage.getItem('receiptObjectId') || ''
          this.getTableStatusFromFB()
        }
      } catch (error) {
        console.error('Error fetching order shop setting:', error)
        this.$toast.error('Failed to retrieve shop settings. Please try again later.')
      }
    },
    async verifyOrderAble() {
      let pinString = localStorage.getItem('pin')

      try {
        const response = await pos({
          method: 'get',
          url: '/api/v3.0/shop/order/able',
          headers: {
            shopObjectId: this.shopObjectId,
            tableObjectId: this.tableObjectId,
            pin: pinString,
          },
        })

        const isAble = response.data?.data?.success || false
        if (isAble) {
          console.log('Kitchen and table are available')
          this.cartLists = JSON.parse(sessionStorage.getItem('cart')) || []
          this.table = JSON.parse(localStorage.getItem('table')) || []
          this.receiptId = localStorage.getItem('receiptId') || ''
          this.receiptObjectId = localStorage.getItem('receiptObjectId') || ''
          this.getTableStatusFromFB()
        } else {
          localStorage.removeItem('pin')
          this.goBackHomePage()
        }
      } catch (error) {
        console.error('Error calling the orderAble API:', error)
        this.$toast.error('Failed to verify order availability. Please try again.')
      }
    },
    goBackHomePage() {
      this.$router.push("/dine-in/home?uid=" + localStorage.uid +
        "&shopObjectId=" + localStorage.shopObjectId +
        "&tableObjectId=" + localStorage.tableObjectId +
        "&pax=" + localStorage.pax)
    },
  },
}
</script>
<style>
.text-color-theme {
  color: var(--bg-color-theme);
}
</style>